import './App.css';
import LandingPage from './components/landing/LandingPage';
import AttendeeSignature from './components/form/AttendeeSignature';
import AttendeeForm from './components/form/AttendeeForm';
import SearchChild from './components/search/SearchChild';
import OverviewPage from './components/overview/OverviewPage';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ChildPageSeparate from './components/child/ChildPageSeparate';
import Logs from './components/logs/Logs';
import RankPage from './components/rank/RankPage';
import StatisticsPage from './components/statistics/StatisticsPage';

function App() {
	return (
		<div className="app-page">
			<BrowserRouter>
				<Routes>
					{/* <Route path="*" element={<Navigate to="/" replace />} /> */}
					<Route exact element={<LandingPage />} path="/"/>
					<Route element={<AttendeeSignature />} path="/sign/:childLocation" />
					<Route element={<AttendeeForm />} path="/form/:childLocation?" />
					<Route element={<SearchChild />} path="/search" />
					<Route element={<OverviewPage />} path="/overview" />
					<Route element={<ChildPageSeparate />} path="/search/:childId" />
					<Route element={<Logs />} path="/logs" />
					<Route element={<RankPage />} path="/rank" />
					<Route element={<StatisticsPage />} path="/statistics" />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
