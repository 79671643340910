import React, { useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';


export default function StatisticsPage() {
    const token = window.localStorage.getItem("token");
    const [location, setLocation] = useState('ANY');
    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').toDate()); // Default date is one month ago
    const [endDate, setEndDate] = useState(moment().toDate()); // Default date is today

    const [statistics, setStatistics] = useState({
        hiddenCount: 0,
        visibleCount: 0,
        new_attendees: 0,
        validated: 0,
        activePerDay: 0,
        mean_price: 0,
        incomeCount: 0,
        discountCount: 0,
        totalIncome: 0,
        cashIncome: 0,
        cardIncome: 0,
        opIncome: 0,
        validationDuration: 0,
        mean_absence: 0,
        mean_age: 0,
    });

    const fetchStatistics = async (location, startDate, endDate) => {
        axios.get(process.env.REACT_APP_API_URL + '/attend/statistics', {
            params: {
                location: location,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setStatistics(res.data);
        }).catch(err => {
            console.log(err);
        });
    }
    
    useState(() => {
        fetchStatistics(location, startDate, endDate);
    }, []);

    return (
        <div className="statistics-page min-vh-100 text-light">
            <h1 className="text-center">Statistici</h1>

            <DropdownButton 
                onSelect={(e) => { 
                    setLocation(e);
                    fetchStatistics(e, startDate, endDate);
                }} 
                title={location === 'ANY' ? "Orice locație" : location} size="lg" variant="light"
            >
                <Dropdown.Item eventKey="ANY" >Orice locație</Dropdown.Item>
                <Dropdown.Item eventKey="OTOPENI" >Otopeni</Dropdown.Item>
                <Dropdown.Item eventKey="DOBROESTI">Dobroesti</Dropdown.Item>
            </DropdownButton>

            <div className='select-date-container d-flex flex-wrap justify-content-center'>
                <div className='d-flex'>
                    <h4 className='align-content-center'>De la:</h4>
                    <div className='w-20 mx-3 py-4'>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            placeholderText="zi/lună/an"
                            className="form-control animate__shakeX"
                            selected={startDate}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            yearDropdownItemNumber={100}
                            onFocus={(e) => e.target.readOnly = true}
                            onChange={val => {
                                setStartDate(val);
                                fetchStatistics(location, val, endDate);
                            }}
                            />
                    </div>
                </div>

                <div className='d-flex'>
                    <h4 className='align-content-center'>Până la:</h4>
                    <div className='w-20 mx-3 py-4'>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            placeholderText="zi/lună/an"
                            className="form-control animate__shakeX"
                            selected={endDate}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            yearDropdownItemNumber={100}
                            onFocus={(e) => e.target.readOnly = true}
                            onChange={val => {
                                setEndDate(val);
                                fetchStatistics(location, startDate, val);
                            }}
                        />
                    </div>
                </div>
            </div>
            
            <div className='text-start mx-4 py-2'>
                <h2 className='d-inline-block bg-warning p-1 px-3 rounded text-dark'>Clienti</h2>

                <p>Copii ascunși: {statistics.hiddenCount}</p>
                <p>Copii noi: {statistics.validated}</p>
                <p>Numarul mediu de copii activi: {statistics.activePerDay.toFixed(2)}</p>
                <p>Durata medie de validare: {statistics.validationDuration.toFixed(0)} zile</p>
                <p>Media absențelor per cursant: {statistics.mean_absence.toFixed(2)}</p>
                <p>Varsta medie: {statistics.mean_age.toFixed(2)}</p>

                <h2 className='d-inline-block bg-primary p-1 px-3 rounded text-white'>Plati</h2>

                <p>Preț mediu: {statistics.mean_price.toFixed(0)} RON</p>
                <p>Număr de încasări: {statistics.incomeCount}</p>
                <p>Număr de reduceri: {statistics.discountCount}</p>
                <p>Încasări totale: {statistics.totalIncome} RON</p>
                <p>Incasari <span style={{ color: 'green' }}>cash</span>: {statistics.cashIncome.toFixed(0)} RON</p>
                <p>Incasari <span style={{ color: 'aqua' }}>card</span>: {statistics.cardIncome.toFixed(0)} RON</p>
                <p>Incasari <span style={{ color: 'goldenrod' }}>OP</span>: {statistics.opIncome.toFixed(0)} RON</p>


            </div>
        </div>
    );
}